<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-0 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group
            label="Search"
            label-for="filter_search"
            label-class="font-weight-bolder"
            class="mb-2"
          >
            <b-input
              id="filter_search"
              v-model="tableRawData.filter"
              type="text"
              size="sm"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            class="mr-2"
            size="sm"
            @click="onShowAddForm"
          >
            Add Raw Data
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
        >
          <b-table
            ref="table"
            small
            hover
            responsive
            show-empty
            class="mt-2"
            :stacked="isMobile()"
            :items="tableRawDataProvider"
            :per-page="tableRawData.perPage"
            :current-page="tableRawData.currentPage"
            :fields="tableRawData.fields"
            :sort-by.sync="tableRawData.sortBy"
            :sort-desc.sync="tableRawData.sortDesc"
            :sort-direction="tableRawData.sortDirection"
            :filter="tableRawData.filter"
            :filter-included-fields="tableRawData.filterOn"
            :busy="tableRawData.busy"
          >
            <template #cell(index)="{ index }">
              {{ Number(tableRawData.currentPage) * Number(tableRawData.perPage) - Number(tableRawData.perPage) + (Number(index) + 1) }}
            </template>

            <template #cell()="{ value }">
              <div class="text-nowrap">
                {{ value }}
              </div>
            </template>

            <template #cell(action)="row">
              <div class="text-md-nowrap d-flex flex-column justify-content-start flex-md-row justify-content-md-center">
                <b-button
                  size="sm"
                  variant="success"
                  :disabled="tableRawData.busy"
                  @click="onShowEditForm(row.item)"
                >
                  Edit
                </b-button>
              </div>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>

        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableRawData.perPage"
              :options="tableRawData.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableRawData.currentPage"
            :total-rows="tableRawData.totalRows"
            :per-page="tableRawData.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-lead-form"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      modal-class="fullscreen"
      :title="modalTitle"
      @ok="onConfirmAction"
    >
      <b-tabs
        t-nav-item-class="font-weight-bold text-uppercase text-success"
        content-class="scrollable"
      >
        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-file-text-line h4" />
              <b class="ml-1">Farmer</b>
            </div>
          </template>
          <ValidationObserver ref="form">
            <form
              role="form"
              novalidate
              @submit.prevent
            >
              <b-row>

                <b-col
                  cols="12"
                >
                  <b-row>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="facebook username"
                        vid="fb_username"
                        rules="max:100"
                      >
                        <b-form-group
                          label="Facebook Username"
                          label-for="fb_username"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        >
                          <b-input
                            id="fb_username"
                            v-model="facebookRaw.fb_username"
                            type="text"
                            maxlength="100"
                            autocomplete="off"
                            placeholder="enter facebook username"
                            :state="(errors.length > 0 ? false : null)"
                            :disabled="state.busy"
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="first name"
                        vid="first_name"
                        rules="required|max:50"
                      >
                        <b-form-group
                          label="First Name"
                          label-for="first_name"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        >
                          <b-input
                            id="first_name"
                            v-model="facebookRaw.first_name"
                            type="text"
                            maxlength="50"
                            autocomplete="off"
                            placeholder="enter first name"
                            :state="(errors.length > 0 ? false : null)"
                            :disabled="state.busy"
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="last name"
                        vid="last_name"
                        rules="required|max:50"
                      >
                        <b-form-group
                          label="Last Name"
                          label-for="last_name"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        >
                          <b-input
                            id="last_name"
                            v-model="facebookRaw.last_name"
                            type="text"
                            maxlength="50"
                            autocomplete="off"
                            placeholder="enter last name"
                            :state="(errors.length > 0 ? false : null)"
                            :disabled="state.busy"
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                  </b-row>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-row>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="source"
                        vid="source"
                        rules="required"
                      >
                        <b-form-group
                          label="Source"
                          label-for="sources"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        >
                          <v-select
                            v-model="selected.source"
                            input-id="sources"
                            label="source_name"
                            placeholder="search source here"
                            :options="list.sources"
                            :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                            :disabled="state.busy || state.fetching.sources"
                            :loading="state.fetching.sources"
                          >
                            <template #option="{ source_name }">
                              <strong>{{ source_name }}</strong>
                            </template>
                            <template #no-options="">
                              No Available Sources
                            </template>
                          </v-select>
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="8"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="street name"
                        vid="street_name"
                        rules="max:255"
                      >
                        <b-form-group
                          label="Street Name"
                          label-for="street_name"
                          label-class="font-weight-bolder"
                          description="Home Address"
                          class="mb-2"
                        >
                          <b-input
                            id="street_name"
                            v-model="facebookRaw.street_name"
                            type="text"
                            autocomplete="off"
                            placeholder="enter street name here"
                            :state="(errors.length > 0 ? false : null)"
                            :disabled="state.busy"
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="province"
                        vid="province"
                        rules="required"
                      >
                        <b-form-group
                          label="Province"
                          label-for="province"
                          label-class="font-weight-bolder"
                          description="Home Address"
                          class="mb-2"
                        >
                          <v-select
                            v-model="selected.province"
                            input-id="province"
                            append-to-body
                            label="province_name"
                            placeholder="search province here"
                            :options="list.provinces"
                            :calculate-position="calculateDropPosition"
                            :class="(errors.length > 0 ? 'is-invalid' : '')"
                            :disabled="(state.busy || state.fetching.provinces)"
                            :loading="state.fetching.provinces"
                          >
                            <template #option="{ province_name }">
                              <strong>{{ province_name }}</strong>
                            </template>
                            <template #no-options="">
                              No Available Provinces
                            </template>
                          </v-select>
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="municipality"
                        vid="municipality"
                        rules="required"
                      >
                        <b-form-group
                          label="Municipality"
                          label-for="municipality"
                          label-class="font-weight-bolder"
                          description="Home Address"
                          class="mb-2"
                        >
                          <v-select
                            v-model="selected.municipality"
                            input-id="municipality"
                            append-to-body
                            label="municipality_name"
                            placeholder="search municipality here"
                            :options="list.municipalities"
                            :calculate-position="calculateDropPosition"
                            :class="(errors.length > 0 ? 'is-invalid' : '')"
                            :disabled="(state.busy || state.fetching.municipalities)"
                            :loading="state.fetching.municipalities"
                          >
                            <template #option="{ municipality_name }">
                              <strong>{{ municipality_name }}</strong>
                            </template>
                            <template #no-options="">
                              No Available Municipalities
                            </template>
                          </v-select>
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="barangay"
                        vid="barangay"
                      >
                        <b-form-group
                          label="Barangay"
                          label-for="barangay"
                          label-class="font-weight-bolder"
                          description="Home Address"
                          class="mb-2"
                        >
                          <v-select
                            v-model="selected.barangay"
                            input-id="barangay"
                            append-to-body
                            label="barangay_name"
                            placeholder="search barangay here"
                            :options="list.barangays"
                            :calculate-position="calculateDropPosition"
                            :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                            :disabled="state.busy || state.fetching.barangays"
                            :loading="state.fetching.barangays"
                          >
                            <template #option="{ barangay_name }">
                              <strong>{{ barangay_name }}</strong>
                            </template>
                            <template #no-options="">
                              No Available Barangays
                            </template>
                          </v-select>
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                  </b-row>
                </b-col>

                <!--
                <b-col cols="12">
                  <ValidationProvider
                    #default="{ errors }"
                    name="cluster"
                    vid="cluster"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="cluster"><strong>Cluster</strong></label>
                      <v-select
                        id="cluster"
                        v-model="selected.cluster"
                        :options="list.clusters"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        :disabled="state.busy || state.fetching.clusters"
                        :loading="state.fetching.clusters"
                        label="cluster_name"
                      >
                        <template #option="{ cluster_name }">
                          <strong>{{ cluster_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Clusters
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col cols="12">
                  <ValidationProvider
                    #default="{ errors }"
                    name="area"
                    vid="area"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="areas"><strong>Area</strong></label>
                      <v-select
                        id="areas"
                        v-model="selected.area"
                        :options="list.areas"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        :disabled="state.busy || state.fetching.areas"
                        :loading="state.fetching.areas"
                        label="area_name"
                      >
                        <template #option="{ area_name }">
                          <strong>{{ area_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Areas
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col> -->

                <b-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="farm address"
                    vid="farm_address"
                    rules="max:255"
                  >
                    <b-form-group
                      label="Farm Address"
                      label-for="farm_address"
                      label-class="font-weight-bolder"
                      class="mb-2"
                    >
                      <b-textarea
                        id="farm_address"
                        v-model="facebookRaw.farm_address"
                        rows="6"
                        max-rows="6"
                        autocomplete="off"
                        placeholder="enter farm address here"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="remarks"
                    vid="remarks"
                    rules="required|max:3000"
                  >
                    <b-form-group
                      label="Remarks"
                      label-for="remarks"
                      label-class="font-weight-bolder"
                      class="mb-2"
                    >
                      <b-textarea
                        id="remarks"
                        v-model="facebookRaw.remarks"
                        rows="6"
                        max-rows="6"
                        autocomplete="off"
                        placeholder="enter remarks here"
                        :state="(errors.length > 0 ? false : null)"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-form-checkbox
                    id="is_owner"
                    v-model="facebookRaw.is_owner"
                    name="is_owner"
                    value="1"
                    unchecked-value="0"
                    switch
                  >
                    <strong>Farm Owner?</strong>
                  </b-form-checkbox>
                </b-col>

              </b-row>
            </form>
          </ValidationObserver>
        </b-tab>

        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-slack-line h4" />
              <b class="ml-1">Crops</b>
            </div>
          </template>
          <ValidationObserver
            ref="formFarmerCrops"
          >
            <form
              role="form"
              novalidate
              @submit.prevent
            >
              <b-row>

                <b-col
                  cols="12"
                >
                  <b-row>

                    <b-col
                      cols="12"
                      md="3"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="crop"
                        vid="crop"
                        rules="required"
                      >
                        <b-form-group
                          label="Crop"
                          label-for="crop"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        >
                          <v-select
                            v-model="tableCrop.selected.crop"
                            input-id="crop"
                            label="crop_name"
                            placeholder="search crop here"
                            :options="tableCrop.options.crops"
                            :class="(errors.length > 0 ? 'is-invalid' : '')"
                            :disabled="(state.busy || tableCrop.fetching.crops)"
                            :loading="tableCrop.fetching.crops"
                          >
                            <template #option="{ crop_name }">
                              <strong>{{ crop_name }}</strong>
                            </template>
                            <template #no-options="">
                              No Available Crops
                            </template>
                          </v-select>
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      v-if="Number(tableCrop.selected.crop?.id) === 4"
                      cols="12"
                      md="3"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="crop name"
                        vid="other_crop"
                        rules="required|max:100"
                      >
                        <b-form-group
                          label="Crop Name"
                          label-for="other_crop"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        >
                          <b-input
                            id="other_crop"
                            v-model="tableCrop.payload.other_crop"
                            type="text"
                            autocomplete="off"
                            placeholder="enter crop name here"
                            :state="(errors.length > 0 ? false : null)"
                            :disabled="state.busy"
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="3"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="field size"
                        vid="crop_field_size"
                        rules="required"
                      >
                        <b-form-group
                          label="Field Size"
                          label-for="crop_field_size"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        >
                          <v-select
                            v-model="tableCrop.selected.crop_field_size"
                            input-id="crop_field_size"
                            label="field_size_name"
                            placeholder="search field size here"
                            :options="tableCrop.options.field_sizes"
                            :class="(errors.length > 0 ? 'is-invalid' : '')"
                            :disabled="(state.busy || tableCrop.fetching.field_sizes)"
                            :loading="tableCrop.fetching.field_sizes"
                          >
                            <template #option="{ field_size_name }">
                              <strong>{{ field_size_name }}</strong>
                            </template>
                            <template #no-options="">
                              No Available Field Sizes
                            </template>
                          </v-select>
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="3"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="number of hectares"
                        vid="number_of_hectares"
                        rules="required|double|min_value:0.01"
                      >
                        <b-form-group
                          label="Number of Hectares"
                          label-for="number_of_hectares"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        >
                          <b-input
                            id="number_of_hectares"
                            v-model="tableCrop.payload.number_of_hectares"
                            type="number"
                            step="0.01"
                            min="0.01"
                            autocomplete="off"
                            class="text-right"
                            placeholder="enter number of hectares here"
                            :state="(errors.length > 0 ? false : null)"
                            :disabled="state.busy"
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <b-button
                        type="button"
                        variant="success"
                        class="mr-2"
                        size="sm"
                        @click="onSaveFarmerCrop"
                      >
                        Save Crop
                      </b-button>
                    </b-col>

                  </b-row>
                </b-col>

                <b-col
                  cols="12"
                >

                  <b-table
                    ref="tableCrop"
                    small
                    hover
                    responsive
                    show-empty
                    class="mt-2"
                    :stacked="isMobile()"
                    :items="facebookRaw.crops"
                    :fields="tableCrop.fields"
                    :busy="tableCrop.busy"
                  >
                    <template #cell(index)="{ index }">
                      {{ (Number(index) + 1) }}
                    </template>

                    <template #cell()="{ value }">
                      <div class="text-nowrap">
                        {{ value }}
                      </div>
                    </template>

                    <template #cell(action)="{ item }">
                      <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-md-start justify-content-center">
                        <b-button
                          size="sm"
                          variant="danger"
                          :disabled="tableCrop.busy"
                          @click="onRemoveFarmerCrop(item.order)"
                        >
                          Remove
                        </b-button>
                      </div>
                    </template>

                    <template #table-busy>
                      <div class="text-center py-5">
                        <b-icon
                          icon="stopwatch"
                          font-scale="5"
                          animation="cylon"
                        />
                        <p class="h3 py-2">
                          <strong>Loading . . .</strong>
                        </p>
                      </div>
                    </template>

                  </b-table>

                </b-col>
              </b-row>
            </form>
          </ValidationObserver>
        </b-tab>

      </b-tabs>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? "Update Record" : "Save Record" }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, UserFacebookRawDataService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'

export default {
  name: 'UserRawDataFacebook',

  middleware: ['auth', 'user'],

  metaInfo: () => ({
    title: 'Facebook'
  }),

  mixins: [formatter, misc],

  data () {
    return {
      state: {
        busy: false,
        editing: false,
        uploading: false,
        fetching: {
          clusters: false,
          areas: false,
          provinces: false,
          municipalities: false,
          barangays: false,
          sources: false
        }
      },
      list: {
        sources: [],
        clusters: [],
        areas: [],
        provinces: [],
        municipalities: [],
        barangays: []
      },
      selected: {
        current: null,
        source: null,
        cluster: null,
        area: null,
        province: null,
        municipality: null,
        barangay: null
      },
      facebookRaw: {
        id: 0,
        fb_username: '',
        first_name: '',
        last_name: '',
        source: null,
        cluster: null,
        area: null,
        home_address: '',
        farm_address: '',
        street_name: '',
        province: null,
        municipality: null,
        barangay: null,
        remarks: '',
        crops: [],
        is_owner: 1
      },
      tableRawData: {
        busy: false,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 10, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { mobile: 2, key: 'status' },
          { mobile: 3, key: 'fb_username', label: 'Facebook Username' },
          { mobile: 4, key: 'first_name', sortable: true },
          { mobile: 5, key: 'last_name', sortable: true },
          { mobile: 6, key: 'province.province_name', label: 'Province' },
          { mobile: 7, key: 'municipality.municipality_name', label: 'Municipality' },
          { mobile: 8, key: 'barangay.barangay_name', label: 'Barangay' },
          { mobile: 9, key: 'updated_at', formatter: this.dateTimeFormatter }
        ].sort(this.scaleOnMobile)
      },
      tableCrop: {
        busy: false,
        fetching: {
          crops: false,
          field_sizes: false
        },
        options: {
          crops: [],
          field_sizes: []
        },
        selected: {
          crop: null,
          crop_field_size: null
        },
        payload: {
          order: 0,
          crop_id: null,
          crop_name: null,
          other_crop: null,
          crop_field_size_id: null,
          crop_field_size_name: null,
          number_of_hectares: null
        },
        fields: [
          { key: 'index', label: '#' },
          { key: 'action' },
          { key: 'crop_name' },
          { key: 'other_crop' },
          { key: 'crop_field_size_name', label: 'Field Size' },
          { key: 'number_of_hectares' }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.editing ? 'Edit Raw Data' : 'Add Raw Data'
    }
  },

  watch: {
    'selected.source' (value) {
      this.facebookRaw.source = value?.id || null
    },

    'selected.cluster' (value) {
      this.facebookRaw.cluster = value?.id || null
      this.getAreas(this.facebookRaw.cluster)
    },

    'selected.area' (value) {
      this.facebookRaw.area = value?.id || null
    },

    'selected.province' (value) {
      this.facebookRaw.province = value?.id || null
      this.getMunicipalities(this.facebookRaw.province)
    },

    'selected.municipality' (value) {
      this.facebookRaw.municipality = value?.id || null
      this.getBarangays(this.facebookRaw.municipality)
    },

    'selected.barangay' (value) {
      this.facebookRaw.barangay = value?.id || null
    },

    'tableCrop.selected.crop' (crop) {
      this.tableCrop.payload.crop_id = crop?.id || null
      this.tableCrop.payload.crop_name = crop?.crop_name || null
    },

    'tableCrop.selected.crop_field_size' (cropFieldSize) {
      this.tableCrop.payload.crop_field_size_id = cropFieldSize?.id || null
      this.tableCrop.payload.crop_field_size_name = cropFieldSize?.field_size_name || null
    }
  },

  mounted () {
    core.index()
    this.getSources()
    this.getProvinces()
    this.getClusters()
  },

  methods: {

    async tableRawDataProvider (ctx) {
      this.tableRawData.busy = true
      return UserFacebookRawDataService.get(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter
        })
      ).then(({ data }) => {
        this.tableRawData.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableRawData.busy = false
      })
    },

    async getSources () {
      this.state.fetching.sources = true
      await SharedListService.getSources().then(({ data }) => {
        const filter = [4, 6]
        this.list.sources = data.filter(item => {
          return filter.includes(item.id)
        })
        this.state.fetching.sources = false
      })
    },

    async getClusters () {
      this.state.fetching.clusters = true
      await SharedListService.getClusters().then(({ data }) => {
        this.list.clusters = data
        this.state.fetching.clusters = false
      })
    },

    async getAreas (cluster) {
      this.selected.area = null
      if (cluster) {
        this.state.fetching.areas = true
        await SharedListService.getAreas(`cluster_id=${cluster}`).then(({ data }) => {
          this.list.areas = data
          this.state.fetching.areas = false
          if (this.state.editing) {
            if (this.selected.current.area) {
              this.selected.area = {
                id: this.selected.current.area.id,
                area_name: this.selected.current.area.area_name
              }
              this.selected.current.area = null
            }
          }
        })
      } else {
        this.list.areas = []
      }
    },

    async getProvinces () {
      this.state.fetching.provinces = true
      await SharedListService.getProvinces().then(({ data }) => {
        this.list.provinces = data
        this.state.fetching.provinces = false
      })
    },

    async getMunicipalities (province) {
      this.selected.municipality = null
      if (province) {
        this.state.fetching.municipalities = true
        await SharedListService.getMunicipalities(`province_id=${province}`).then(({ data }) => {
          this.list.municipalities = data
          this.state.fetching.municipalities = false
          if (this.state.editing) {
            if (this.selected.current.municipality) {
              this.selected.municipality = {
                id: this.selected.current.municipality.id,
                municipality_name: this.selected.current.municipality.municipality_name
              }
              this.selected.current.municipality = null
            }
          }
        })
      } else {
        this.list.municipalities = []
      }
    },

    async getBarangays (municipality) {
      this.selected.barangay = null
      if (municipality) {
        this.state.fetching.barangays = true
        await SharedListService.getBarangays(`municipality_id=${municipality}`).then(({ data }) => {
          this.list.barangays = data
          this.state.fetching.barangays = false
          if (this.state.editing) {
            if (this.selected.current.barangay) {
              this.selected.barangay = {
                id: this.selected.current.barangay.id,
                barangay_name: this.selected.current.barangay.barangay_name
              }
              this.selected.current.barangay = null
            }
          }
        })
      } else {
        this.list.barangays = []
      }
    },

    async getCrops () {
      this.tableCrop.options.crops = []
      this.tableCrop.fetching.crops = false
      return new Promise(resolve => {
        SharedListService.getCrops().then(({ data }) => {
          this.tableCrop.options.crops = data
        }).finally(() => {
          resolve([])
          this.tableCrop.fetching.crops = false
        })
      })
    },

    async getFieldSizes () {
      this.tableCrop.options.field_sizes = []
      this.tableCrop.fetching.field_sizes = false
      return new Promise(resolve => {
        SharedListService.getFieldSizes().then(({ data }) => {
          this.tableCrop.options.field_sizes = data
        }).finally(() => {
          resolve([])
          this.tableCrop.fetching.field_sizes = false
        })
      })
    },

    onShowAddForm () {
      this.state.editing = false
      this.selected.source = null
      this.selected.cluster = null
      this.selected.area = null
      this.selected.province = null
      this.selected.municipality = null
      this.selected.barangay = null
      this.facebookRaw.id = 0
      this.facebookRaw.t = 1
      this.facebookRaw.fb_username = ''
      this.facebookRaw.first_name = ''
      this.facebookRaw.last_name = ''
      this.facebookRaw.source = null
      this.facebookRaw.cluster = null
      this.facebookRaw.area = null
      this.facebookRaw.street_name = ''
      this.facebookRaw.province = null
      this.facebookRaw.municipality = null
      this.facebookRaw.barangay = null
      this.facebookRaw.farm_address = ''
      this.facebookRaw.remarks = ''

      this.facebookRaw.crops = []
      this.facebookRaw.is_owner = 1

      this.tableCrop.selected.crop = null
      this.tableCrop.selected.crop_field_size = null

      this.tableCrop.payload.order = 0
      this.tableCrop.payload.crop_id = null
      this.tableCrop.payload.crop_name = null
      this.tableCrop.payload.other_crop = null
      this.tableCrop.payload.crop_field_size_id = null
      this.tableCrop.payload.crop_field_size_name = null
      this.tableCrop.payload.number_of_hectares = null

      this.getCrops().then(() => {
        this.getFieldSizes()
      })

      this.$bvModal.show('modal-lead-form')
    },

    onShowEditForm (current) {
      this.state.editing = true
      this.selected.current = { ...current }
      this.selected.source = null
      this.selected.cluster = null
      this.selected.area = null
      this.selected.province = null
      this.selected.municipality = null
      this.selected.barangay = null
      this.facebookRaw.id = current.id
      this.facebookRaw.t = current.t
      this.facebookRaw.fb_username = current.fb_username
      this.facebookRaw.first_name = current.first_name
      this.facebookRaw.last_name = current.last_name
      this.facebookRaw.street_name = current.street_name
      this.facebookRaw.farm_address = current.farm_address
      this.facebookRaw.remarks = current.remarks

      if (current.source) {
        this.selected.source = {
          id: current.source.id,
          source_name: current.source.source_name
        }
      }

      if (current.cluster) {
        this.selected.cluster = {
          id: current.cluster.id,
          cluster_name: current.cluster.cluster_name
        }
      }

      if (current.area) {
        this.selected.area = {
          id: current.area.id,
          area_name: current.area.area_name
        }
      }

      if (current.province) {
        this.selected.province = {
          id: current.province.id,
          province_name: current.province.province_name
        }
      }

      if (current.municipality) {
        this.selected.municipality = {
          id: current.municipality.id,
          municipality_name: current.municipality.municipality_name
        }
      }

      if (current.barangay) {
        this.selected.barangay = {
          id: current.barangay.id,
          barangay_name: current.barangay.barangay_name
        }
      }

      this.tableCrop.selected.crop = null
      this.tableCrop.selected.crop_field_size = null

      this.tableCrop.payload.order = 0
      this.tableCrop.payload.crop_id = null
      this.tableCrop.payload.crop_name = null
      this.tableCrop.payload.other_crop = null
      this.tableCrop.payload.crop_field_size_id = null
      this.tableCrop.payload.crop_field_size_name = null
      this.tableCrop.payload.number_of_hectares = null

      this.facebookRaw.is_owner = current.is_owner || 1
      this.facebookRaw.crops = current.crops || []

      this.facebookRaw.crops = this.facebookRaw.crops.sort(
        (a, b) => Number(a.order) - Number(b.order)
      )

      this.getCrops().then(() => {
        this.getFieldSizes()
      })

      this.$bvModal.show('modal-lead-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          let title = 'Submit Facebook Raw Data?'

          if (this.state.editing) {
            title = 'Save changes on this Raw Data?'
          }

          this.$swal.fire({
            icon: 'question',
            title: 'Confirmation',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: this.state.editing ? 'Save' : 'Submit',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (this.state.editing) {
                return this.onFormPutSubmit()
              } else {
                return this.onFormPostSubmit()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        UserFacebookRawDataService.post(this.facebookRaw).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-lead-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        UserFacebookRawDataService.put(this.facebookRaw).then(({ data }) => {
          this.$bvModal.hide('modal-lead-form')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.table.localItems, { id: data.facebook_raw.id })
            row.t = data.facebook_raw.t
            row.fb_username = data.facebook_raw.fb_username
            row.first_name = data.facebook_raw.first_name
            row.last_name = data.facebook_raw.last_name
            row.street_name = data.facebook_raw.street_name
            row.source = data.facebook_raw.source
            row.cluster = data.facebook_raw.cluster
            row.area = data.facebook_raw.area
            row.province = data.facebook_raw.province
            row.municipality = data.facebook_raw.municipality
            row.barangay = data.facebook_raw.barangay
            row.farm_address = data.facebook_raw.farm_address
            row.remarks = data.facebook_raw.remarks
            row.crops = data.facebook_raw.crops
            row.is_owner = data.facebook_raw.is_owner
            row.updated_at = data.facebook_raw.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    onSaveFarmerCrop () {
      this.$refs.formFarmerCrops.validate().then(
        isAllFieldAreValid => {
          if (isAllFieldAreValid) {
            const currentLength = this.facebookRaw.crops.length

            this.tableCrop.payload.order = currentLength + 1

            if (currentLength > 0) {
              this.tableCrop.payload.order = this.facebookRaw.crops[currentLength]?.order ? (
                Number(this.facebookRaw.crops[currentLength]?.order) + 1
              ) : this.tableCrop.payload.order
            }

            this.facebookRaw.crops.push({
              ...this.tableCrop.payload
            })

            this.tableCrop.selected.crop = null
            this.tableCrop.selected.crop_field_size = null

            this.tableCrop.payload.order = 0
            this.tableCrop.payload.crop_id = null
            this.tableCrop.payload.crop_name = null
            this.tableCrop.payload.other_crop = null
            this.tableCrop.payload.crop_field_size_id = null
            this.tableCrop.payload.crop_field_size_name = null
            this.tableCrop.payload.number_of_hectares = null

            this.facebookRaw.crops = this.facebookRaw.crops.sort(
              (a, b) => Number(a.order) - Number(b.order)
            )

            this.$refs.formFarmerCrops.reset()
          } else {
            this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            })
          }
        }
      )
    },

    onRemoveFarmerCrop (order) {
      this.facebookRaw.crops = this.facebookRaw.crops.filter(
        crop => Number(crop.order) !== Number(order)
      ).map(
        (raw, order) => ({
          ...raw,
          order: Number(order) + 1
        })
      ).sort(
        (a, b) => Number(a.order) - Number(b.order)
      )
    }
  }
}
</script>
